import { isTabbable, iterateFocusableElements } from './utils/iterate-focusable-elements.js';
import { polyfill as eventListenerSignalPolyfill } from './polyfills/event-listener-signal.js';
eventListenerSignalPolyfill();
const suspendedTrapStack = [];
let activeTrap = undefined;
function tryReactivate() {
    const trapToReactivate = suspendedTrapStack.pop();
    if (trapToReactivate) {
        focusTrap(trapToReactivate.container, trapToReactivate.initialFocus, trapToReactivate.originalSignal);
    }
}
function followSignal(signal) {
    const controller = new AbortController();
    signal.addEventListener('abort', () => {
        controller.abort();
    });
    return controller;
}
function getFocusableChild(container, lastChild = false) {
    return iterateFocusableElements(container, { reverse: lastChild, strict: true, onlyTabbable: true }).next().value;
}
export function focusTrap(container, initialFocus, abortSignal) {
    const controller = new AbortController();
    const signal = abortSignal !== null && abortSignal !== void 0 ? abortSignal : controller.signal;
    container.setAttribute('data-focus-trap', 'active');
    let lastFocusedChild = undefined;
    function ensureTrapZoneHasFocus(focusedElement) {
        if (focusedElement instanceof HTMLElement && document.contains(container)) {
            if (container.contains(focusedElement)) {
                lastFocusedChild = focusedElement;
                return;
            }
            else {
                if (lastFocusedChild && isTabbable(lastFocusedChild) && container.contains(lastFocusedChild)) {
                    lastFocusedChild.focus();
                    return;
                }
                else if (initialFocus && container.contains(initialFocus)) {
                    initialFocus.focus();
                    return;
                }
                else {
                    const containerNeedsTemporaryTabIndex = container.getAttribute('tabindex') === null;
                    if (containerNeedsTemporaryTabIndex) {
                        container.setAttribute('tabindex', '-1');
                    }
                    container.focus();
                    if (containerNeedsTemporaryTabIndex) {
                        container.addEventListener('blur', () => container.removeAttribute('tabindex'), { once: true });
                    }
                    return;
                }
            }
        }
    }
    const wrappingController = followSignal(signal);
    container.addEventListener('keydown', event => {
        if (event.key !== 'Tab' || event.defaultPrevented) {
            return;
        }
        const { target } = event;
        const firstFocusableChild = getFocusableChild(container);
        const lastFocusableChild = getFocusableChild(container, true);
        if (target === firstFocusableChild && event.shiftKey) {
            event.preventDefault();
            lastFocusableChild === null || lastFocusableChild === void 0 ? void 0 : lastFocusableChild.focus();
        }
        else if (target === lastFocusableChild && !event.shiftKey) {
            event.preventDefault();
            firstFocusableChild === null || firstFocusableChild === void 0 ? void 0 : firstFocusableChild.focus();
        }
    }, { signal: wrappingController.signal });
    if (activeTrap) {
        const suspendedTrap = activeTrap;
        activeTrap.container.setAttribute('data-focus-trap', 'suspended');
        activeTrap.controller.abort();
        suspendedTrapStack.push(suspendedTrap);
    }
    wrappingController.signal.addEventListener('abort', () => {
        activeTrap = undefined;
    });
    signal.addEventListener('abort', () => {
        container.removeAttribute('data-focus-trap');
        const suspendedTrapIndex = suspendedTrapStack.findIndex(t => t.container === container);
        if (suspendedTrapIndex >= 0) {
            suspendedTrapStack.splice(suspendedTrapIndex, 1);
        }
        tryReactivate();
    });
    document.addEventListener('focus', event => {
        ensureTrapZoneHasFocus(event.target);
    }, { signal: wrappingController.signal, capture: true });
    ensureTrapZoneHasFocus(document.activeElement);
    activeTrap = {
        container,
        controller: wrappingController,
        initialFocus,
        originalSignal: signal
    };
    const suspendedTrapIndex = suspendedTrapStack.findIndex(t => t.container === container);
    if (suspendedTrapIndex >= 0) {
        suspendedTrapStack.splice(suspendedTrapIndex, 1);
    }
    if (!abortSignal) {
        return controller;
    }
}
